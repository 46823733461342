import { Button, Card, Col, Descriptions, Row, Skeleton, Tag } from "antd";
import * as S from "./AreaDetailStyle";
import { useTranslation } from "react-i18next";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  EyeOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { findByIdUser, listFarmsService } from "../service";
import { AdapterListArea } from "../ListAreaByProperties/AdapterListArea";
import { PROGRAM_AREA_MAP_STATUS } from "../../../Enums/ProgramAreaMapStatus";
import { signatureLink } from "../../Farmers/service";

export const AreaDetailPage = () => {
  const { identifier, id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [myAgent, setMyAgent] = useState({});
  const [area, setArea] = useState({});
  const [statusesAvaliable, setStatuseAvaliable] = useState({
    deforestation: {
      status: PROGRAM_AREA_MAP_STATUS.PENDING,
      color: "gold",
      icon: <SyncOutlined spin />,
    },
    indigenous_lands: {
      status: PROGRAM_AREA_MAP_STATUS.PENDING,
      color: "gold",
      icon: <SyncOutlined spin />,
    },
    preservation_area: {
      status: PROGRAM_AREA_MAP_STATUS.PENDING,
      color: "gold",
      icon: <SyncOutlined spin />,
    },
  });

  const loadAreaFarm = useCallback(async () => {
    try {
      setLoading(true);
      const responseFarm = await listFarmsService({ id });
      if (responseFarm.result === 0) return;

      const farm = responseFarm.result[0];

      const _areas = AdapterListArea(farm.maps, farm._id);

      const areaSelected = _areas.find(
        (area) => area.identifier === identifier
      );
      if (!areaSelected) {
        navigate(`/program-area/${id}/list-program-area-by-farm`);
        return;
      }

      const response = await signatureLink(areaSelected?.image_link)
      
      setArea({...areaSelected, farm_id: farm._id, image_link: response});

      handleMyAgent(farm.agents);
      handleStatusEligibility(farm.program);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }, []);

  const handleMyAgent = useCallback(async (agents) => {
    const agent = agents.find((agent) => agent.ordering === 1);
    if (!agent) return;
    const responseAgent = await findByIdUser({ _id: agent.agent_id });

    const agentDetail =
      responseAgent.result.length > 0 ? responseAgent.result[0] : null;

    setMyAgent(agentDetail);
  }, []);

  const handleStatusEligibility = useCallback(
    async (programs) => {
      if (!programs) return;
      if (!programs.label === 0) return;

      const program = programs[0];
      let _statusesAvaliable = {...statusesAvaliable};

      program.program.rules.forEach((criterion) => {
        criterion.rules.forEach((rule) => {
          if (rule.key === 2.3) {
            _statusesAvaliable = { 
              ..._statusesAvaliable,
              deforestation: { status: rule.status, ...hashColorAndIcon(rule.status) } };
          } else if (rule.key === 2.1) {
            _statusesAvaliable = { 
              ..._statusesAvaliable,
              indigenous_lands: { status: rule.status, ...hashColorAndIcon(rule.status) } };
          } else if (rule.key === 2.2) {
            _statusesAvaliable = { 
              ..._statusesAvaliable,
              preservation_area: { status: rule.status, ...hashColorAndIcon(rule.status) } };
          }
        });
      });
      setStatuseAvaliable(_statusesAvaliable);
    },
    [statusesAvaliable]
  );

  const hashColorAndIcon = (status) => {
    switch (status) {
      case PROGRAM_AREA_MAP_STATUS.APPROVED:
        return {
          color: "success",
          icon: <CheckCircleOutlined />,
        };
      case PROGRAM_AREA_MAP_STATUS.PENDING:
        return {
          color: "gold",
          icon: <SyncOutlined spin />,
        };
      case PROGRAM_AREA_MAP_STATUS.REJECTED:
        return {
          color: "warn",
          icon: <CloseCircleOutlined />,
        };
      case PROGRAM_AREA_MAP_STATUS.REVIEW:
        return {
          color: "bold",
          icon: <EyeOutlined />,
        };
      default:
        return {
          color: "gold",
          icon: <CheckCircleOutlined />,
        };
    }
  };

  useEffect(() => {
    loadAreaFarm();
  }, []);

  if (loading) return <Skeleton paragraph={{ rows: 3 }} active={true} />;

  return (
    <S.Container>
      <S.WrapperButtonBack size="middle">
        <Button
          shape="round"
          type="dashed"
          onClick={() =>
            navigate(`/program-area/${id}/list-program-area-by-farm`)
          }
          icon={<ArrowLeftOutlined />}
        >
          {"Voltar"}
        </Button>
      </S.WrapperButtonBack>
      <Card style={{ marginTop: "60px" }}>
        <Col xs={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={6} style={{ maxWidth: 260 }}>
              <S.ImageArea preview={true} src={area.image_link} alt="Imagem" />
            </Col>
            <Col xs={8} md={8} style={{ minWidth: 230 }}>
              <Descriptions>
                <Descriptions.Item label={t("Status")}>
                  {(() => {
                    let statusTag;
                    switch (area.status) {
                      case PROGRAM_AREA_MAP_STATUS.PENDING:
                        statusTag = (
                          <Tag icon={<SyncOutlined spin />} color="gold">
                            {t("PENDING")}
                          </Tag>
                        );
                        break;
                      case PROGRAM_AREA_MAP_STATUS.REJECTED:
                        statusTag = (
                          <Tag icon={<CloseCircleOutlined />} color="error">
                            {t("REJECTED")}
                          </Tag>
                        );
                        break;
                      case PROGRAM_AREA_MAP_STATUS.REVIEW:
                        statusTag = (
                          <Tag icon={<SyncOutlined spin />} color="warning">
                            {t("REVIEW")}
                          </Tag>
                        );
                        break;
                      case PROGRAM_AREA_MAP_STATUS.APPROVED:
                        statusTag = <Tag color="success">{t("APPROVED")}</Tag>;
                        break;
                      case PROGRAM_AREA_MAP_STATUS.SIGNED:
                        statusTag = <Tag color="success">{t("SIGNED")}</Tag>;
                        break;
                      default:
                        statusTag = <span>Status Desconhecido</span>;
                    }
                    return statusTag;
                  })()}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions>
                <Descriptions.Item>
                  <S.WrapperInfo>
                    <S.TotalArea>
                      {area && parseFloat(area.area_size).toFixed(2)} ha
                    </S.TotalArea>
                    <S.DescriptionArea>{area?.area_name}</S.DescriptionArea>
                    <Tag color="success">{t(area?.area_type)}</Tag>
                  </S.WrapperInfo>
                </Descriptions.Item>
              </Descriptions>
             {area?.area_type !== 'FARM_BOUNDARIES' && <Descriptions>
                <Descriptions.Item label={t('label_type_cultivation')}>{area?.type_cultivation}</Descriptions.Item>
              </Descriptions>}
              <Descriptions>
                <Descriptions.Item label={t('label_agent')}></Descriptions.Item>
              </Descriptions>
              <Descriptions>
                <Descriptions.Item>
                  <S.WrapperInfo>
                    <S.NameAgent>{myAgent?.name}</S.NameAgent>
                    <S.DescriptionOrganization>
                      {myAgent?.organization?.name}
                    </S.DescriptionOrganization>
                  </S.WrapperInfo>
                </Descriptions.Item>
              </Descriptions>
            </Col>

            <Col xs={24} md={8} style={{ minWidth: 320 }}>
              <Descriptions title="Status dos Critérios de Eligibilidade">
                <Descriptions.Item label={"Deforestation"}>
                  <Tag
                    icon={statusesAvaliable.deforestation.icon}
                    color={statusesAvaliable.deforestation.color}
                  >
                    {t(statusesAvaliable.deforestation.status)}
                  </Tag>
                </Descriptions.Item>
              </Descriptions>
              <Descriptions>
                <Descriptions.Item label={"Indigenous Lands"}>
                  <Tag
                    icon={statusesAvaliable.indigenous_lands.icon}
                    color={statusesAvaliable.indigenous_lands.color}
                  >
                    {t(statusesAvaliable.indigenous_lands.status)}
                  </Tag>
                </Descriptions.Item>
              </Descriptions>
              <Descriptions>
                <Descriptions.Item label={"Preservation Area"}>
                  <Tag
                    icon={statusesAvaliable.preservation_area.icon}
                    color={statusesAvaliable.preservation_area.color}
                  >
                    {t(statusesAvaliable.preservation_area.status)}
                  </Tag>
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Col>
        <S.WrapperButtonNavigate size="middle">
          <Button
            shape="round"
            type="dashed"
            onClick={() => navigate(`/program-map/${area.farm_id}`)}
            icon={<EyeOutlined />}
          >
            {"Visualizar no Mapa"}
          </Button>
        </S.WrapperButtonNavigate>
      </Card>
    </S.Container>
  );
};
