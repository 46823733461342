import {
  MailOutlined,
  PhoneOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Card, Col, Rate, Row } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaMapMarkerAlt } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { listActionsPlans } from "../ActionPlans/service";
import { countFarmsPerAgent, getAgent } from "../AdministrationUsers/service";
import { listChecklist } from "../Checklist/service";

export default function AgentsView() {
  const { id } = useParams();

  const { t } = useTranslation();

  const [agent, setAgent] = useState({});
  const [countFarms, setcountFarms] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [loading, setLoading] = useState(false);
  const [countActionsPlan, setcountActionsPlan] = useState("");
  const [countChecklist, setcountChecklist] = useState("");

  const findAgents = useCallback(async () => {
    setLoading(true);
    const result = await getAgent(id);
    setAgent(result);
  }, [id]);
  useEffect(() => {
    findAgents();
  }, [findAgents]);

  const countFarmsPerAgents = useCallback(async () => {
    setLoading(true);
    const result = await countFarmsPerAgent(pageSize, currentPage, id);
    setcountFarms(result.data.count);
    setLoading(false);
  }, [pageSize, currentPage, id]);
  useEffect(() => {
    countFarmsPerAgents();
  }, [countFarmsPerAgents]);

  const findChecklist = useCallback(async () => {
    const result = await listChecklist({
      agentId: id,
    });
    setcountChecklist(result.result.length);
  }, [id]);
  useEffect(() => {
    findChecklist();
  }, [findChecklist]);

  const findActionPlans = useCallback(async () => {
    const result = await listActionsPlans({
      agentId: id,
    });
    setcountActionsPlan(result.count);
  }, [id]);

  useEffect(() => {
    findActionPlans();
  }, [findActionPlans]);

  const onChange = (key) => {
    console.log(key);
  };

  const navigate = useNavigate();

  return (
    <div>
      <div>
        <Button
          onClick={() => navigate(`/administration-users/${id}/edit-agent`)}
          shape="round"
          className="confirm_button"
          type="dashed"
          icon={<PlusOutlined />}
        >
          {t("Edit_User")}
        </Button>
      </div>
      <Row style={{ marginTop: "20px" }} gutter={[16, 16]} justify="start">
        <Col xs={12} sm={12} md={21}>
          <Col
            style={{ display: "flex", justifyContent: "space-between" }}
            className="custom-card"
          >
            <div style={{ display: "flex", alignItems: "start" }}>
              <h1
                style={{
                  fontSize: "2rem",
                  marginTop: "1px",
                }}
              >
                <UserOutlined size={48} />
              </h1>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                <h1
                  style={{
                    fontSize: "25px",
                    marginBottom: "4px",
                    marginTop: "10px",
                  }}
                >
                  {agent.name}
                </h1>
                <div
                  style={{
                    fontSize: "20px",
                    display: "flex",
                    gridTemplateColumns: "repeat(3)",
                    gap: "16px",
                    alignItems: "start",
                    justifyContent: "flex-start",
                    color: "rgb(79, 175, 73)",
                    marginLeft: "-30px",
                  }}
                >
                  {agent.regions
                    ? agent.regions.map((item) => (
                        <div key={item.name}>
                          <FaMapMarkerAlt style={{ marginRight: "6px" }} />
                          {item.name}
                        </div>
                      ))
                    : ""}
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  <MailOutlined size={24} />
                </h1>

                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {agent.email}
                </h1>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  <PhoneOutlined size={24} />
                </h1>
                <h1 style={{ fontSize: "1.0rem", color: "#555555" }}>
                  {agent.phone}
                </h1>
              </div>
            </div>
          </Col>
        </Col>
      </Row>

      <Row style={{ marginTop: "10px" }} gutter={[16, 16]} justify="start">
        <Col xs={24} sm={12} md={4}>
          <Card
            onClick={() => navigate(`/agents/${id}/actions-farmers-by-agents`)}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <div
                className="centered-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h1
                  style={{
                    fontSize: "2rem",
                    alignSelf: "center",
                  }}
                >
                  {countFarms}
                </h1>
                <h4 style={{ fontSize: "20px", color: "#555555" }}>
                  {t("Farmers")}
                </h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Card
            onClick={() => navigate(`/agents/${id}/actions-plans-agent`)}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <div
                className="centered-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h1
                  style={{
                    fontSize: "2rem",
                    alignSelf: "center",
                  }}
                >
                  {countActionsPlan}
                </h1>
                <h4 style={{ fontSize: "20px", color: "#555555" }}>
                  {t("Actions_Plan")}
                </h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={4}>
          <Card
            onClick={() => navigate(`/agents/${id}/checklists-agent`)}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <div
                className="centered-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <h1
                  style={{
                    fontSize: "2rem",
                    alignSelf: "center",
                  }}
                >
                  {countChecklist}
                </h1>
                <h4 style={{ fontSize: "20px", color: "#555555" }}>
                  {t("Completed_checklist")}
                </h4>
              </div>
            </div>
          </Card>
        </Col>

        <Col xs={24} sm={12} md={4}>
          <Card
            onClick={() => navigate(`/agents/${id}/rate-agent`)}
            style={{ cursor: "pointer" }}
            className="custom-card"
          >
            <div className="card-content">
              <div
                className="centered-content"
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <h1 style={{ fontSize: "1rem" }}>
                  {<Rate disabled value={agent.rate} />}
                </h1>
                <h4 style={{ fontSize: "20px", color: "#555555" }}>
                  {t("Rating")}
                </h4>
              </div>
            </div>
          </Card>
        </Col>
        <Col xs={24} sm={12} md={5}>
          <Card
            onClick={() => navigate("/dashboard")}
            style={{ backgroundColor: "rgb(79, 175, 73)" }}
            className="custom-card"
          >
            <div className="card-content">
              <div
                className="centered-content"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <h1 style={{ fontSize: "2rem", color: "white" }}>7</h1>
                <h4 style={{ fontSize: "20px", color: "white" }}>
                  {t("Next_Appointments")}
                </h4>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
