import { Col, Descriptions, Row, Tag } from "antd";
import * as S from "./BCProfilerFarmerStyles";
import { useTranslation } from "react-i18next";
import { STATUS_FARM } from "../../Enums/Farm.enum";
import {
  CloseCircleOutlined,
  SyncOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";

import { useEffect, useState } from "react";

import { fetchIntersectedAreas } from "../service";

import { useNavigate } from "react-router-dom";

export const BCProfilerFarmer = ({ farm }) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [hasIntersection, setHasIntersection] = useState(false);

  const checkIntersection = async () => {
    const intersections = await fetchIntersectedAreas(farm._id);

    if (intersections.length > 0) {
      setHasIntersection(true);
    }
  };

  useEffect(() => {
    if (farm._id) {
      checkIntersection();
    }
  }, [farm._id]);

  return (
    <S.Container>
      <Row>
        <Col xs={24}>
          <Row gutter={[16, 16]}>
            <Col xs={24} md={6} style={{ minWidth: 280 }}>
              <img
                src="https://img.freepik.com/fotos-premium/imagem-rural-em-uma-fazenda-com-portao-de-madeira-estrada-de-terra-sol-e-colinas-ao-fundo_337748-36.jpg"
                alt="Imagem"
                style={{ width: "246px", height: "auto", borderRadius: 8 }}
              />
            </Col>
            <Col xs={24} md={18}>
              <Descriptions title={t("Farm_details")}>
                <Descriptions.Item label={t("Farm")}>
                  <div>
                    {farm?.name}
                    <br />
                    <Tag color="default">{farm.identifier}</Tag>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={t("Farmer")}>
                  <div>
                    {farm?.farmer?.name}
                    <br />
                    <Tag color="default">{farm.farmer?.identifier}</Tag>
                  </div>
                </Descriptions.Item>
                <Descriptions.Item label={t("Location")}>
                  {farm.city} - {farm.state}
                </Descriptions.Item>
                <Descriptions.Item label={t("Phone")}>
                  {farm.farmer?.phone_number ?? t("Uninformed")}
                </Descriptions.Item>
                <Descriptions.Item label={t("Email")}>
                  {farm.farmer?.email}
                </Descriptions.Item>
                <Descriptions.Item label={t("Area")}>
                  {parseFloat(farm.total_area).toFixed(2)} ha
                </Descriptions.Item>
                <Descriptions.Item label={"Status"}>
                  {(() => {
                    if (!farm.program) return <></>;
                    switch (farm.program[0].status) {
                      case STATUS_FARM.IN_PROGRESS:
                        return (
                          <Tag icon={<SyncOutlined spin />} color="processing">
                            {t("IN_PROGRESS")}
                          </Tag>
                        );
                      case STATUS_FARM.APPLICATION_DENIED:
                        return (
                          <Tag icon={<CloseCircleOutlined />} color="error">
                            {t("APPLICATION_DENIED")}
                          </Tag>
                        );
                      case STATUS_FARM.FLAGGED_FOR_REVIEW:
                        return (
                          <Tag icon={<SyncOutlined spin />} color="warning">
                            {t("FLAGGED_FOR_REVIEW")}
                          </Tag>
                        );
                      case STATUS_FARM.ENROLLED_TO_THE_PROGRAM:
                        return (
                          <Tag color="success">
                            {t("ENROLLED_TO_THE_PROGRAM")}
                          </Tag>
                        );
                      default:
                        return <span>Status Desconhecido</span>;
                    }
                  })()}
                </Descriptions.Item>

                <Descriptions.Item></Descriptions.Item>

                <Descriptions.Item>
                  {hasIntersection && (
                    <S.IntersectedAreas
                      onClick={() => navigate(`/farmers/overlap/${farm._id}`)}
                    >
                      <div>
                        {S.IconOverlay}
                        <p>{t("overlap")}</p>
                      </div>
                      <ArrowRightOutlined />
                    </S.IntersectedAreas>
                  )}
                </Descriptions.Item>
              </Descriptions>
            </Col>
          </Row>
        </Col>
      </Row>
    </S.Container>
  );
};
